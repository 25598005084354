
import Home from './components/pages/Home'
import ErrorPage from './components/pages/Error'

let routes = [
    {   
        path: '/', // ensure home is first item
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },
    { 
        path: "*", // ensure Error is last item
        name: 'error',
        component: ErrorPage,
        meta: { 
            title: 'Error!',
            hidden: true 
        },
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
}