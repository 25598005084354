<template>
  <default v-if="loaded">
    <router-view></router-view>
  </default>
</template>


<script>
  import Default from '~/components/layouts/Default.vue'

  export default {
    components: { Default },
    data(){
      return {
        breakpoint: '',
      }
    },
    computed: {
      loaded() {
        return this.$store.state.loaded
      },
    },
  	methods: {
  		setup() {
  			this.breakpointCheck();
  		},
  		resize(e) {
  			this.breakpointCheck();
  		},
      breakpointCheck(resize) {
        let w = window.innerWidth;
        let breakpoint = '';

        // check these values against /assets/scss/_variables
        if (w <= 576) {
          breakpoint = 'xs';
        } else if (w > 576 && w <= 767) {
          breakpoint = 'sm';
        } else if (w >= 768 && w <= 991) {
          breakpoint = 'md';
        } else if (w >= 992 && w <= 1199){
          breakpoint = 'lg';
        } else {
          breakpoint = 'xl';
        }

        if (breakpoint !== this.breakpoint) {
          this.breakpoint = breakpoint
          this.$store.commit('setBreakpoint', breakpoint);
          // console.log('Breakpoint : ' + breakpoint)
        }
      },
    },
    created() {
      this.setup();
      window.addEventListener("resize", this.resize);
      this.$store.commit('setLoaded', true);
    }
  }
</script>