
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics';
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import App from './components/App.vue'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Store)
Vue.use(Helpers)
Vue.use(InlineSvgPlugin)

Vue.use(VueAnalytics, {
  id: 'UA-57647470-1',
  router
})

const router = new VueRouter(Router)
const store = new Vuex.Store(Store)

new Vue({
	router,
	store,
	render: createElement => createElement(App)
}).$mount('#app');