export default {
  state: {
    loaded: false,
    breakpoint: '',
  },
  mutations: {
    setLoaded (state, payload){
      state.loaded = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
  },
  actions: {
  }
}