<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>

  export default {
    data() {
      return {
      }
    },
    computed: {
    },
  }
</script>
