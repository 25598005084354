<template>
  <main class="container">
    <div class="grid">
      <p><strong>Error.</strong><br>Nothing to see here.</p>
    </div>
  </main>
</template>


<script>

  export default {
    data(){
      return {
      }
    },
    computed: {
    }
  }
</script>